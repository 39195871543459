<template>
  <div>
    <!-- Row Start -->
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Shipping Zone</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="outline-primary" v-b-modal.add-zone-modal
                >Add Shipping Zone</b-button
              >
            </div>
          </div>

          <div class="card-body body-fluid">
            <b-table responsive striped hover :items="zones" :fields="fields">
              <template #cell(actions)="row">
                <b-button @click="ViewZone(row.item.id)" variant="primary"
                  >Edit</b-button
                >
                <b-button
                  @click="deletezone(row.item.id)"
                  class="ml-2"
                  variant="danger"
                  >Delete</b-button
                >
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <!-- Row End -->
    <!-- Add Modal -->
    <b-modal
      id="add-zone-modal"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addzone"
      title="Add Zone"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-4">
            <b-form-group label="Zone Name">
              <b-form-input v-model="form.name"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Sync list">
              <b-form-select
                :options="synclist"
                value-field="val"
                v-model="form.sync_list"
                text-field="name"
              >
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Currency">
              <b-form-select
                :options="currencies"
                value-field="id"
                v-model="form.currency_id"
                text-field="code"
              >
              </b-form-select>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      zones: [],
      currencies: [],
      isLoading: false,
      errors: [],
      synclist: [
        { val: "all", name: "All" },
        { val: "user", name: "User" },
        { val: "origin", name: "Origin" },
        { val: "destination", name: "Destination" },
        { val: "user-origin", name: "User - Origin" },
        { val: "user-destination", name: "User - Destination" },
        { val: "origin-destination", name: "Origin - Destination" },
        { val: null, name: "None" },
      ],
      fields: [
        {
          key: "id",
          label: "Zone ID",
        },
        { key: "name", label: "Zone Name" },
        {
          key: "created_at",
          label: "Created At",
          formatter: (value) => {
            return new Date(value).toLocaleString();
          },
        },
        {
          key: "updated_at",
          label: "Updated At",
          formatter: (value) => {
            return new Date(value).toLocaleString();
          },
        },
        { key: "actions", label: "Actions" },
      ],
      form: {
        name: null,
        sync_list: "all",
        currency_id: null,
        id: null,
      },
    };
  },
  components: {
    Loading,
  },
  created() {
    this.getAllZones();
    this.getAllCC();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Shipping" },
      { title: "Zone" },
    ]);
  },
  methods: {
    checkErrors() {
      if (this.form.name && this.form.currency_id) {
        return true;
      }
      this.errors = [];
      if (!this.form.name) {
        this.errors.push("Name is required.");
      }
      //   if (!this.form.sync_list) {
      //     this.errors.push("Sync List is required.");
      //   }
      if (!this.form.currency_id) {
        this.errors.push("Currency List is required.");
      }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    ViewZone(id) {
      this.$router.push({ name: "view-zone", params: { id: id } });
    },
    resetModal() {
      this.form.name = null;
      this.form.sync_list = "all";
      this.form.currency_id = null;
    },
    addzone(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.post("shippingzone", this.form)
          .then(() => {
            this.getAllZones();
            this.$nextTick(() => {
              this.$bvModal.hide("add-zone-modal");
              this.makeToastVariant(
                "success",
                "Zone added successfully",
                "Success"
              );
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    // updatecc(bvModalEvt) {
    //   bvModalEvt.preventDefault();
    //   if (this.checkErrors() == true) {
    //     ApiService.update("currencyconversion", this.form.id, this.form)
    //       .then(() => {
    //         this.getAllCC();
    //         this.$nextTick(() => {
    //           this.$bvModal.hide("edit-cc-modal");
    //           this.makeToastVariant(
    //             "info",
    //             "Currency Conversion updated successfully",
    //             "Success"
    //           );
    //         });
    //       })
    //       .catch(({ response }) => {
    //         for (let field of Object.keys(response.data.error)) {
    //           this.makeToastVariant(
    //             "danger",
    //             response.data.error[field][0],
    //             "Error"
    //           );
    //         }
    //       });
    //   }
    // },
    getAllZones() {
      this.isLoading = true;
      ApiService.get("shippingzone")
        .then(({ data }) => {
          
          this.zones = data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getAllCC() {
      ApiService.get("currencyconversion")
        .then(({ data }) => {
          
          this.currencies = data;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    // ShowEditModal(id) {
    //   ApiService.get("currencyconversion", id)
    //     .then(({ data }) => {
    //       this.form = data;
    //       this.$bvModal.show("edit-cc-modal");
    //     })
    //     .catch(() => {
    //       // context.commit(SET_ERROR, response.data.errors);
    //       // context.commit(SET_ERROR, response);
    //     });
    // },
    deletezone(id) {
      this.isLoading = true;
      ApiService.delete("shippingzone", id)
        .then(() => {
          this.getAllZones();
          this.makeToastVariant(
            "warning",
            "Zone deleted successfully",
            "Success"
          );
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
  },
};
</script>
